import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '@common/ui-whitelabel-components';
import { StepCompletionNavigationGuard } from '@shared/data-access-navigation';
import { QuoteCmsPageResolver } from './resolvers/quote-cms-page.resolver';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: '.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    // TODO: add a guard
    path: 'quote',
    loadChildren: () =>
      import('@edf-appliances/feature-quote-details').then(
        (module) => module.EdfAppliancesFeatureQuoteDetailsModule
      ),
    resolve: {
      cmsData: QuoteCmsPageResolver,
    },
    canActivate: [StepCompletionNavigationGuard],
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'quote.html',
    redirectTo: '/quote',
    pathMatch: 'full',
  },
  {
    path: 'products',
    loadChildren: () =>
      import('@edf-appliances/feature-appliance-details').then(
        (module) => module.EdfAppliancesFeatureApplianceDetailsModule
      ),
    canActivate: [StepCompletionNavigationGuard],
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'products.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@sales/feature-checkout-personal-details').then(
            (m) => m.SalesFeatureCheckoutPersonalDetailsModule
          ),
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@sales/feature-checkout-direct-debit').then(
            (m) => m.SalesFeatureCheckoutDirectDebitModule
          ),
        canActivate: [StepCompletionNavigationGuard],
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
          import('@edf/feature-order-confirmation').then(
            (module) => module.EdfFeatureOrderConfirmationModule
          ),
      },
    ],
  },
  { path: 'error', data: { title: 'Error' }, component: ErrorPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class EdfAppliancesFeatureShellRouterModule {} // Main routes for application
